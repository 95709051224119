
html {
  background: #fafafa;
  color: #222;
}

pre {
  vertical-align: bottom;
  padding: 15px;
  font-size: 100%;
  background: #eee;
  overflow-x: auto;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace,
    sans-serif;
  font-size: 1rem;
}

img {
  width: 100%;
}

a {
  color: #222;
  text-decoration: none;
  border-bottom: 2px solid #b6ccd8;
}

a:hover {
  color: #739db4;
  border-bottom: 2px solid #739db4;
}

figure {
  margin: 0;
  padding: 0;
}

#main {
  padding: 0 5%;
  max-width: 720px;
}

#breadcrumbs {
  margin-top: 25px;
  font-size: 0.8rem;
}

// #footer {
//   margin: 1rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// #footer-content {
//   max-width: 720px;
// }
